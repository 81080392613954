import React from "react";
import ReactDOM from "react-dom";
import { AppProps } from "next/app";
import Head from "next/head";
import Router from "next/router";

import PageChange from "../components/PageChange/PageChange";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/tailwind.css";

Router.events.on("routeChangeStart", (url) => {
	console.log(`Loading: ${url}`);
	document.body.classList.add("body-page-transition");
	ReactDOM.render(
		<PageChange path={url} />,
		document.getElementById("page-transition")
	);
});
Router.events.on("routeChangeComplete", () => {
	ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
	document.body.classList.remove("body-page-transition");
});
Router.events.on("routeChangeError", () => {
	ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
	document.body.classList.remove("body-page-transition");
});

export default function MyApp( { Component, pageProps }: AppProps ) {

	const Layout = (Component as any).layout || (({ children }) => <>{children}</>);

	const title = "EarthNodes.net | A World Mobile Earth Node Operator";
	const description = 'EarthNodes.net is a World Mobile Earth Node operator helping connect the unconnected. Earth Nodes are the backbone of the World Mobile Network and process the underlining transactions and functions. Delegate to EarthNodes.net.';

	return (
		<React.Fragment>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>

				<title>{title}</title>
				<meta property="og:title" content={title}/>
				<meta name="twitter:title" content={title}/>
				<meta property="og:site_name" content={title}/>
				<meta name="Description" content={description} />
				<meta property="og:description" content={description} />
				<meta property="twitter:description" content={description} />
			</Head>
			<Layout>
				<Component {...pageProps} />
			</Layout>
		</React.Fragment>
	);
	
}